import axios from 'axios';
import { apiServer, apiServerV2 } from './index';
import { devConfig } from '../config';


export function checkProfilePermission(payload) {
  console.log("checkProfilePermission", payload)
  return apiServerV2.post(
    `discover/profile-access/status`, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function unlockProfile(id) {
  return apiServerV2.get(`discover/${id}/unlock`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function fetchNewInfluencer(payload) {
  return axios.post(
    `${devConfig.API_HOST}/api/v1/campaign/19/social_media/create/planner_profile/`,
    payload,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`
      } 
    })
}


export function fetchCuratedList(platform, category, search, page, rowsPerPage) {
  let url = `discover/curated-all?platform=${platform}`;
  if (category) {
    url += `&category=${category}`;
  }
  if (search) {
    url += `&search=${search}`
  }
  if (page && rowsPerPage){
    url += `&page=${page}&rows_per_page=${rowsPerPage}`
  }
  return apiServer.get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Currated List:', error);
      throw error;
    });
}

export function fetchCuratedInfluencers(id, page) {
  return apiServer.get(`discover/curated-list?id=${id}&page=${page}`)
  .then((response) => {
    console.log('API Response:', response); // Log the entire response object
    return response.data;
  })
    .catch((error) => {
      console.error('Error in get Currated List:', error);
      throw error;
    });
}

export function getDiscoveryInfluencersDetails(id) {
  return apiServerV2.get(`/discover/${id}`).then((response) => {
    return response.data;
  });
}

